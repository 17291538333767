@import '../../scss/variables.scss';
@import '../../scss/mixin.scss';

.table-margin {
    margin-top: 20px;
}

.table-booking {
    overflow: auto;
}

._width-table {
    min-width: 1200px;
}

.table-input {
    border: 1px solid $color-header-title;
    width: 100%;
}

.table-control {
    display: flex;
    justify-content: center;
    padding: 10px;
    &__body {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 760px){
            width: 300px;
            padding: 10px;
            flex-direction: column;
            gap: 10px;
        }
    }
    &__select {
        flex: 0 1 20%;
    }
    &__btn-group {
    }

}

.page-list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}